<template>
  <div class="fd--mt-3 fd-h-full">
    <div class="fd-mt-1 fd-mb-4">
      <BreadcrumbCustom />
    </div>
    <div class="fd-relative fd-w-full fd-h-full fd-flex fd-items-stretch" style="min-height: 500px">
      <Sidebar
        :showSidebar="showSidebar"
        :nav-menu-items="getVerticalNavbarList"
        :title="name"
        :thumbnail="thumbnail"
        @openEditSidebar="$emit('openEditSidebar')"
        @hideSidebar="sidebar = false"
        :hasEditIcon="hasEditIcon"
      />
      <div
        class="menu-icon-class fd-absolute fd-left-0 fd-top-1 fd-px-4 fd-py-2 fd-bg-white fd-rounded-sm"
        @click="sidebar = !sidebar"
      >
        <feather-icon icon="MenuIcon" />
      </div>
      <div class="content-custom">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/components/global/Sidebar";
import BreadcrumbCustom from "@/views/components/global/BreadcrumbCustom";

export default {
  name: "LayoutVerticalCustom",
  components: {BreadcrumbCustom, Sidebar},
  data() {
    return {
      sidebar: false
    }
  },
  computed: {
    showSidebar() {
      return this.sidebar
    },
    getVerticalNavbarList() {
      return this.verticalNavbarList
    }
  },
  props: {
    verticalNavbarList: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: String,
      default: ''
    },
    hasEditIcon: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
}
</script>

<style lang="scss" scoped>
.content-custom {
  margin-left: 0;
  margin-top: 30px;
  width: 100%;
  min-height: 500px;
  box-shadow: 0 0 15px rgba(0,0,0, .14);
  background: #ffffff !important;
  border-radius: 5px;
}
.menu-icon-class {
  display: block;
}
@media screen and (min-width: 1200px) {
  .menu-icon-class {
    display: none;
  }
  .content-custom {
    margin-top: 0;
    margin-left: 13px;
  }
}
</style>