<template>
  <div
    class="sidebar-container"
    style="min-height: 500px;"
    id="custom-sidebar-container"
    @click="closeSidebar"
  >
    <div class="main-menu-custom fd-bg-white fd-py-5 fd-overflow-hidden" id="main-menu-custom-id">
      <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-px-3">
        <div
          class="fd-w-full fd-grid fd-items-center"
          :style="[checkHasThumbnail ? {gridTemplateColumns: '.19fr 1.6fr .2fr'} : {gridTemplateColumns: '3fr .2fr'}]"
        >
          <img v-if="thumbnail" :src="thumbnail" alt="" style="width: 24px; height: 24px; border-radius: 6px; object-fit: cover;">
          <p
            class="fd-block fd-text-lg fd-font-semibold fd-text-theme-2 fd-truncate"
            :class="[thumbnail ? 'fd-ml-2' : '']"
            style="margin-bottom: 0 !important;"
          >
            {{ title }}
          </p>
          <SvgLoader v-if="hasEditIcon" :name="'settingIcon'" @click.native="openEditSidebar" class="fd-cursor-pointer fd-justify-self-end" />
        </div>
      </div>
      <ul
        class="fd-w-full fd-h-full fd-overflow-y-scroll fd-mt-2"
      >
        <li
          v-for="(navMenuItem, index) in navMenuItems"
          :key="index"
          @click="goTo(navMenuItem)"
          class="navbar--items fd-w-full fd-py-4 fd-cursor-pointer fd-px-3 fd-font-semibold fd-text-sm"
          :class="[
            isActive(navMenuItem) ?
            'fd-text-theme-2 fd-border-l-2 fd-border-theme-4' :
            'fd-text-theme-10'
          ]"
        >
          <div v-if="'children' in navMenuItem">
            <span @click="toggleSubMenu(navMenuItem.route.name)" class="fd-flex fd-items-center fd-justify-start">
              <SvgLoader :name="navMenuItem.icon" class="icon-svg" :class="[isActive(navMenuItem) ? 'active' : '']" />
              <span class="fd-ml-4">{{ $t(`secondSidebar.${navMenuItem.title}`) }}</span>
            </span>
            <ul
              v-show="subMenuSelected === navMenuItem.route.name"
              class="fd-w-full fd-h-full fd-overflow-y-scroll fd-mt-2 fd-pt-3"
            >
              <li
                v-for="(child, index) in navMenuItem.children"
                :key="index"
                @click="goTo(child)"
                class="navbar--items fd-w-full fd-py-2.5 fd-cursor-pointer fd-px-3 fd-font-semibold fd-text-sm"
                :class="[
                isActive(child) ?
                'fd-text-theme-2' :
                'fd-text-theme-10'
              ]"
              >
              <span class="fd-flex fd-items-center fd-justify-start">
                <SvgLoader :name="child.icon" class="icon-svg" :class="[isActive(child) ? 'active' : '']" />
                <span class="fd-ml-4">{{ $t(`secondSidebar.${child.title}`) }}</span>
              </span>
              </li>
            </ul>
          </div>

          <div v-else class="fd-flex fd-items-center fd-justify-between">
            <div class="fd-flex fd-items-center fd-justify-start">
              <SvgLoader :name="navMenuItem.icon" class="icon-svg" :class="[isActive(navMenuItem) ? 'active' : '']" />
              <span class="fd-ml-4">{{ $t(`secondSidebar.${navMenuItem.title}`) }}</span>
            </div>
            <div v-if="'showCount' in navMenuItem && navMenuItem.showCount">
              <span class="hidden">{{ setGetterName(navMenuItem.getterName) }}</span>
              <span class="fd-font-normal fd-block fd-text-xs fd-text-theme-4 fd-py-0.5 fd-px-1.5 fd-rounded-full" style="background-color: #E9EDEB">{{ total }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    navMenuItems: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: String,
      default: ''
    },
    showSidebar: {
      type: Boolean,
      default: false
    },
    hasEditIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      route: null,
      subMenuSelected: '',
      getterName: ''
    }
  },
  watch: {
    showSidebar: {
      handler(newVal) {
        const sidebarContainer = document.getElementById('custom-sidebar-container')
        const el = document.getElementById('main-menu-custom-id')

        if (newVal) {
          sidebarContainer.classList.add('active')
          return el.classList.add('active')
        }

        sidebarContainer.classList.remove('active')
        el.classList.remove('active')
      }
    }
  },
  computed: {
    total: {
      get () {
        return this.$store.getters[this.getterName]
      },
      set (getterName) {
        this.getterName = getterName
      }
    },
    checkHasThumbnail() {
      return !! this.thumbnail
    }
  },
  methods: {
    isActive(nav) {
      if ('children' in nav) {
        let active = false
        nav.children.forEach(item => {
          if (this.$route.name === item.route.name) {
            this.subMenuSelected = nav.route.name
            active = true
          }
        })
        return active
      }

      return this.$route.name === nav.route.name
    },
    goTo(item) {
      if (this.$route.name === item.route.name) return
      if ('children' in item) return
      this.subMenuSelected = ''
      document.getElementById('custom-sidebar-container').classList.remove('active')
      this.$router.push(item.route)
      return this.$emit('hideSidebar')
    },
    openEditSidebar() {
      document.getElementById('custom-sidebar-container').classList.remove('active')
      document.getElementById('main-menu-custom-id').classList.remove('active')
      this.$emit('hideSidebar')
      return this.$emit('openEditSidebar')
    },
    closeSidebar(event) {
      if (event.target['id'] === 'custom-sidebar-container') {
        document.getElementById('custom-sidebar-container').classList.remove('active')
        document.getElementById('main-menu-custom-id').classList.remove('active')
        return this.$emit('hideSidebar')
      }
    },
    toggleSubMenu(routeName) {
      if (this.subMenuSelected === routeName) {
        this.subMenuSelected = ''
        return;
      }
      this.subMenuSelected = routeName
    },
    setGetterName(getterName) {
      this.total = getterName
    }
  }
}
</script>

<style lang="scss" scoped>
.main-menu-custom {
  width: 254px;
  height: 100%;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 15px rgba(0,0,0, .14);

  ::v-deep svg {
    &.icon-svg path {
      fill: #B5B8B5 !important;
    }
    &.active path{
      fill: #202A21 !important;
    }
  }

  .navbar--items {
    & > span {
      transition: all .2s linear;

      &:hover {
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .sidebar-container.active {
    background: rgba(26, 32, 44, 0.26);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
  }
  .main-menu-custom {
    position: fixed;
    right: 100%;
    z-index: 999;
    top: 0;

    &.active {
      left: 0 !important;
      right: auto !important;
    }
  }
}
</style>
