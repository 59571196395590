var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-container",staticStyle:{"min-height":"500px"},attrs:{"id":"custom-sidebar-container"},on:{"click":_vm.closeSidebar}},[_c('div',{staticClass:"main-menu-custom fd-bg-white fd-py-5 fd-overflow-hidden",attrs:{"id":"main-menu-custom-id"}},[_c('div',{staticClass:"fd-w-full fd-flex fd-items-center fd-justify-between fd-px-3"},[_c('div',{staticClass:"fd-w-full fd-grid fd-items-center",style:([_vm.checkHasThumbnail ? {gridTemplateColumns: '.19fr 1.6fr .2fr'} : {gridTemplateColumns: '3fr .2fr'}])},[(_vm.thumbnail)?_c('img',{staticStyle:{"width":"24px","height":"24px","border-radius":"6px","object-fit":"cover"},attrs:{"src":_vm.thumbnail,"alt":""}}):_vm._e(),_c('p',{staticClass:"fd-block fd-text-lg fd-font-semibold fd-text-theme-2 fd-truncate",class:[_vm.thumbnail ? 'fd-ml-2' : ''],staticStyle:{"margin-bottom":"0 !important"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.hasEditIcon)?_c('SvgLoader',{staticClass:"fd-cursor-pointer fd-justify-self-end",attrs:{"name":'settingIcon'},nativeOn:{"click":function($event){return _vm.openEditSidebar.apply(null, arguments)}}}):_vm._e()],1)]),_c('ul',{staticClass:"fd-w-full fd-h-full fd-overflow-y-scroll fd-mt-2"},_vm._l((_vm.navMenuItems),function(navMenuItem,index){return _c('li',{key:index,staticClass:"navbar--items fd-w-full fd-py-4 fd-cursor-pointer fd-px-3 fd-font-semibold fd-text-sm",class:[
          _vm.isActive(navMenuItem) ?
          'fd-text-theme-2 fd-border-l-2 fd-border-theme-4' :
          'fd-text-theme-10'
        ],on:{"click":function($event){return _vm.goTo(navMenuItem)}}},[('children' in navMenuItem)?_c('div',[_c('span',{staticClass:"fd-flex fd-items-center fd-justify-start",on:{"click":function($event){return _vm.toggleSubMenu(navMenuItem.route.name)}}},[_c('SvgLoader',{staticClass:"icon-svg",class:[_vm.isActive(navMenuItem) ? 'active' : ''],attrs:{"name":navMenuItem.icon}}),_c('span',{staticClass:"fd-ml-4"},[_vm._v(_vm._s(_vm.$t(("secondSidebar." + (navMenuItem.title)))))])],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.subMenuSelected === navMenuItem.route.name),expression:"subMenuSelected === navMenuItem.route.name"}],staticClass:"fd-w-full fd-h-full fd-overflow-y-scroll fd-mt-2 fd-pt-3"},_vm._l((navMenuItem.children),function(child,index){return _c('li',{key:index,staticClass:"navbar--items fd-w-full fd-py-2.5 fd-cursor-pointer fd-px-3 fd-font-semibold fd-text-sm",class:[
              _vm.isActive(child) ?
              'fd-text-theme-2' :
              'fd-text-theme-10'
            ],on:{"click":function($event){return _vm.goTo(child)}}},[_c('span',{staticClass:"fd-flex fd-items-center fd-justify-start"},[_c('SvgLoader',{staticClass:"icon-svg",class:[_vm.isActive(child) ? 'active' : ''],attrs:{"name":child.icon}}),_c('span',{staticClass:"fd-ml-4"},[_vm._v(_vm._s(_vm.$t(("secondSidebar." + (child.title)))))])],1)])}),0)]):_c('div',{staticClass:"fd-flex fd-items-center fd-justify-between"},[_c('div',{staticClass:"fd-flex fd-items-center fd-justify-start"},[_c('SvgLoader',{staticClass:"icon-svg",class:[_vm.isActive(navMenuItem) ? 'active' : ''],attrs:{"name":navMenuItem.icon}}),_c('span',{staticClass:"fd-ml-4"},[_vm._v(_vm._s(_vm.$t(("secondSidebar." + (navMenuItem.title)))))])],1),('showCount' in navMenuItem && navMenuItem.showCount)?_c('div',[_c('span',{staticClass:"hidden"},[_vm._v(_vm._s(_vm.setGetterName(navMenuItem.getterName)))]),_c('span',{staticClass:"fd-font-normal fd-block fd-text-xs fd-text-theme-4 fd-py-0.5 fd-px-1.5 fd-rounded-full",staticStyle:{"background-color":"#E9EDEB"}},[_vm._v(_vm._s(_vm.total))])]):_vm._e()])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }