export default [
  {
    title: 'Projects',
    route: { name: 'rsManagement.projects' },
    icon: 'projectsIcon',
  },
  {
    title: 'rsMembers',
    route: { name: 'rsManagement.rsMembers' },
    icon: 'membersIcon',
  },
  {
    title: 'rsInformation',
    route: { name: 'rsManagement.information' },
    icon: 'informationIcon',
  },
]