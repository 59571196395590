<template>
  <div class="full-height">
    <LayoutVerticalCustom
      :hasEditIcon="false"
      :verticalNavbarList="verticalNavbarList"
      :name="phaseDetails ? phaseDetails.name : ''"
    />
  </div>
</template>

<script>
import LayoutVerticalCustom from "@/layouts/vertical/LayoutVerticalCustom";
import verticalRsNavbar from "@/views/components/RsManagement/verticalRsNavbar";
import {mapGetters} from "vuex";

export default {
name: "Rs",
  components: {LayoutVerticalCustom},
  data() {
   return {
     verticalNavbarList: [],
   }
  },
  created() {
    this.getRsDetails()
    this.verticalNavbarList = verticalRsNavbar
  },
  computed: {
    ...mapGetters({
      phaseDetails: 'phase/phaseDetails'
    }),
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    getRsDetails() {

    }
  }
}
</script>

<style scoped>

</style>